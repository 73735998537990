<template>
  <!-- start for web -->
  <v-card class="elevation-0" v-if="!this.$root.mobile">
    <v-card-title>
      {{ $t("APPOINTMENTS.TABS.upcoming") }}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        :label="$t('APPOINTMENTS.APP.search')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="apps"
      :search="search"
      :loading="loadingStatus"
      :locale="this.$root.lang"
      :loading-text="$t('tLoading')"
    >
      <!-- #item.owner -->
      <template v-slot:item.doctor.doctor_id.first_name="{ item }">
        <Avatar :user="item.doctor.doctor_id"></Avatar>
      </template>
      <template v-slot:item.status="{ item }">
        <div class="d-flex align-items-center">
          <span
            v-if="item.status == 'published'"
            class="dot-blue primary ml-3"
            v-b-tooltip.hover.right="$t('APPOINTMENTS.APP.a_status.pub')"
          ></span>
          <span
            v-if="item.status == 'deleted'"
            class="dot-red danger ml-3"
            v-b-tooltip.hover.right="$t('APPOINTMENTS.APP.a_status.del')"
          ></span>
          <span
            v-if="item.status == 'draft'"
            class="dot-red red ml-3"
            v-b-tooltip.hover.right="$t('APPOINTMENTS.APP.a_status.del')"
          ></span>
          <span
            v-if="item.status == 'pending'"
            class="dot-blue primary ml-3"
            v-b-tooltip.hover.right="$t('APPOINTMENTS.APP.a_status.pending')"
          ></span>
          <span
            v-if="item.status == 'progress'"
            class="dot-yellow warning ml-3"
            v-b-tooltip.hover.right="$t('APPOINTMENTS.APP.a_status.pro')"
          ></span>
          <span
            v-if="item.status == 'complete'"
            class="dot-green success ml-3"
            v-b-tooltip.hover.right="$t('APPOINTMENTS.APP.a_status.com')"
          ></span>
        </div>
      </template>
      <template v-slot:item.date="{ item }">
        <div class="d-flex align-items-center">
          <span class="text-dark-75 font-weight-bold font-size-sm mr-2">{{
            item.date
          }}</span>
        </div>
      </template>
      <template v-slot:item.time="{ item }">
        <div class="d-flex align-items-center">
          <span class="text-dark-75 font-weight-bold font-size-sm mr-2">{{
            (item.date_time + "Z")
              | moment("timezone", getTimeZone(), "hh:mm A")
          }}</span>
          <span class="text-dark-75 font-weight-light font-size-sm"
            >({{
              (item.date_time + "Z")
                | moment("timezone", getTimeZone(), "from", "now")
            }})</span
          >
        </div>
      </template>
      <template v-slot:item.type="{ item }">
        <i
          v-b-tooltip.hover.right="$t('APPOINTMENTS.APP.type_d.video')"
          v-if="item.type == 'video'"
          class="fas fa-video"
        ></i>
        <i
          v-b-tooltip.hover.right="$t('APPOINTMENTS.APP.type_d.clinic')"
          v-if="item.type == 'clinic'"
          class="fas fa-user-friends"
        ></i>
      </template>
      <template v-slot:item.action="{ item }">
        <a
          @click="deleteAppointment(item.id)"
          v-if="item.status != 'progress'"
          href=""
          class="btn btn-danger mx-2"
        >
          <i class="flaticon2-trash mx-1"></i
          >{{ $t("APPOINTMENTS.APP.actions.delete") }}
        </a>
        <router-link
          :to="'/appointment/' + item.id"
          v-slot="{ href, navigate }"
          v-if="item.status == 'progress'"
        >
          <a :href="href" class="btn btn-success mr-4" @click="navigate">
            <v-icon class="">mdi-play-circle</v-icon>
          </a>
        </router-link>
      </template>
    </v-data-table>
  </v-card>
  <!-- end for web -->

  <!-- start for mobile -->
  <div class="bg-transparent" v-else>
    <div
      v-if="loadingStatus"
      class="text-center"
      style="width: 100%; margin-right: auto; margin-left: auto"
    >
      <v-skeleton-loader
        v-for="i in skltoload"
        :key="i"
        class="my-2"
        height="80"
        type="list-item-avatar-three-line"
      ></v-skeleton-loader>
    </div>
    <v-list v-else class="bg-transparent">
      <template>
        <div v-for="item in apps" :key="item.id" class="mb-2 listitem">
          <v-list-item style="min-height:80px">
            <v-list-item-avatar size="47" color="primary">
              <v-img
                v-if="item.doctor.doctor_id.avatar != null"
                :src="item.doctor.doctor_id.avatar.data.thumbnails[0].url"
              ></v-img>
              <div
                v-else
                style="text-transform: uppercase;width:100%"
                class="white--text font-size-h4"
              >
                {{ item.doctor.doctor_id.first_name.charAt(0) }}
              </div>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                <div class="listitemtitle" v-if="item.type == 'video'">
                  {{ $t("APPOINTMENTS.APP.vidwith") }}<br />
                  {{ item.doctor.doctor_id.first_name }}
                  {{ item.doctor.doctor_id.last_name }}
                </div>
                <div class="listitemtitle" v-else-if="item.type == 'clinic'">
                  {{ $t("APPOINTMENTS.APP.clinicwith") }}<br />
                  {{ item.doctor.doctor_id.first_name }}
                  {{ item.doctor.doctor_id.last_name }}
                </div>
                <div class="listitemsubtitle">
                  {{
                    (item.date_time + "Z")
                      | moment(
                        "timezone",
                        getTimeZone(),
                        "ddd DD/MM/YYYY hh:mm A"
                      )
                  }}
                  {{
                    (item.date_time + "Z")
                      | moment("timezone", getTimeZone(), "from", "now")
                  }}
                </div>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                @click="deleteAppointment(item.id)"
                small
                fab
                elevation="0"
                color="rgba(239, 165, 179, 0.25)"
                v-if="item.status != 'progress'"
              >
                <unicon
                  style="margin-right: auto;margin-left: auto;"
                  name="trash-alt"
                  fill="#f64e60"
                  height="20"
                ></unicon>
              </v-btn>
              <router-link
                :to="'/appointment/' + item.id"
                v-slot="{ navigate }"
                v-else
              >
                <v-btn
                  small
                  fab
                  elevation="0"
                  color="rgba(165, 203, 239, 0.20)"
                  @click="navigate"
                >
                  <unicon
                    style="margin-right: auto;margin-left: auto;"
                    name="video"
                    fill="#0971CE"
                    height="20"
                  ></unicon>
                </v-btn>
              </router-link>
            </v-list-item-action>
          </v-list-item>
        </div>
      </template>
    </v-list>

    <div
      v-if="apps.length == 0 && !loadingStatus"
      class="text-muted font-size-h4"
      style="text-align: center;
    margin-top: 40px; margin-bottom:40px"
    >
      {{ $t("APPOINTMENTS.APP.noappsfound") }}
    </div>
    <v-dialog width="70" v-model="deletedialg" persistent>
      <v-card
        height="70"
        color="white"
        dark
        style="display: flex;justify-content: center;align-items: center;border-radius: 10px;"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-card>
    </v-dialog>
  </div>
  <!-- end for mobile -->
</template>

<script>
import ApiService from "@/core/services/api.service";
import Avatar from "@/view/pages/utilComponents/avatar2.vue";

export default {
  name: "AppointmentUpcoming",
  components: { Avatar },
  props: ["reload", "skltoload"],
  data() {
    return {
      search: "",
      error: "",
      loadingStatus: true,
      deletedialg: false,
      headers: [
        {
          text: this.$t("APPOINTMENTS.APP.doctor"),
          align: "left",
          sortable: true,
          value: "doctor.doctor_id.first_name"
        },
        { text: this.$t("APPOINTMENTS.APP.status"), value: "status" },
        { text: this.$t("APPOINTMENTS.APP.date"), value: "date" },
        { text: this.$t("APPOINTMENTS.APP.time"), value: "time" },
        { text: this.$t("APPOINTMENTS.APP.type"), value: "type" },
        {
          text: this.$t("APPOINTMENTS.APP.action"),
          value: "action",
          align: "right"
        }
      ],
      apps: []
    };
  },
  mounted() {
    this.getAppointments();
    this.$util.EventBus.$on("updateAppointmentstatus", data => {
      // console.log("data from event, i'm in upcoming patient apps",data)
      let id = this.apps.findIndex(a => {
        return a.id == data.data.id;
      });
      if (id >= 0) this.apps[id].status = data.data.status;
    });
  },
  watch: {
    reload: function(newreload, oldreload) {
      // console.log("new reload: ", newreload, "oldreload", oldreload);
      if (newreload > oldreload) {
        this.loadingStatus = true;
        this.getAppointments();
      }
    }
  },
  methods: {
    getTimeZone() {
      return this.$util.getTimezone();
    },
    async getAppointments() {
      this.$moment.locale("en");
      var userInfo = JSON.parse(localStorage.userInfo).id;
      // console.log(
      //   'the url is: ',
      //   'appointments?fields=id,status,video_room_id,rating,type,date,time,date_time,medical_file,' +
      //     'doctor.doctor_id.first_name,doctor.doctor_id.last_name,doctor.doctor_id.avatar.data,doctor.doctor_id.id,' +
      //     'rating&filter[medical_file.owner]=' +
      //     userInfo +
      //     '&filter[date_time][gte]=' +
      //     this.$moment().format()
      // );
      await ApiService.get(
        "items",
        "appointments?fields=id,status,video_room_id,rating,type,date,time,date_time,medical_file," +
          "doctor.doctor_id.first_name,doctor.doctor_id.last_name,doctor.doctor_id.avatar.data,doctor.doctor_id.id," +
          "rating&filter[medical_file.owner]=" +
          userInfo +
          "&filter[status][in]=progress,pending&sort=date_time"
      )
        .then(({ data }) => {
          // console.log(data.data);
          data.data = data.data.map(d => {
            d.doctor.doctor_id =
              d.doctor.doctor_id === null
                ? { first_name: "Doctory", last_name: "Doctor" }
                : d.doctor.doctor_id;
            return d;
          });
          this.apps = data.data;
          this.loadingStatus = false;
          this.$moment.locale(localStorage.language);
        })
        .catch(error => {
          this.error = error;
        });
    },
    removefromlist(id) {
      var i = this.apps.findIndex(item => {
        // console.log("item",item)
        return item.id == id;
      });
      // console.log("index to be deleted", i);
      this.apps.splice(i, 1);
    },
    async deleteAppointment(id) {
      this.deletedialg = true;
      // console.log(`delete app with id ${id}`);
      const payload = { status: "deleted" };
      //this.loadingStatus = true;
      await ApiService.update("items", `appointments/${id}`, payload);

      this.removefromlist(id);
      this.deletedialg = false;
      //this.getAppointments();
      //this.loadingStatus = false;
    }
  }
};
</script>
<style scoped></style>
